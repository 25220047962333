<template>
  <section class="form-common">
    <h2 class="form-common__title">{{ t("question_remain") }}</h2>
    <h3 class="form-common__subTitle">
      {{ t("push_button") }} <span @click="toggle">тут</span> {{ t("form") }}
    </h3>

    <transition
      name="aside"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:before-leave="beforeLeave"
      v-on:leave="leave"
    >
      <form
        id="form-common"
        class="form-common__form form-questions"
        v-show="show"
      >
        <fieldset
          class="form-questions__item"
          v-bind:class="{ error: v$.name.$error, focus: valueName }"
        >
          <input
            id="name"
            type="text"
            name="name"
            autocomplete="off"
            v-model="state.name"
            class="form-questions__input"
          />
          <label for="name" class="form-questions__label">{{ t("FIO") }}</label>
        </fieldset>
        <fieldset
          class="form-questions__item"
          v-bind:class="{ error: v$.email.$error, focus: valueEmail }"
        >
          <input
            id="email"
            type="email"
            name="email"
            v-model="state.email"
            class="form-questions__input"
          />
          <label for="email" class="form-questions__label">{{
            t("email")
          }}</label>
        </fieldset>
        <fieldset
          class="form-questions__item"
          v-bind:class="{ error: v$.tel.$error, focus: valueTel }"
        >
          <!-- Документация https://github.com/iamstevendao/vue-tel-input -->
          <vue-tel-input
            ref="myinput"
            v-mask="mask"
            v-model="state.tel"
            :inputOptions="{
              id: 'tel',
              type: 'tel',
              name: 'tel',
              required: 'required',
              autocomplete: 'off',
            }"
            @close="
              state.tel = '+ ' + pref;
              this.$refs.myinput.focus();
            "
            mode="international"
            class="form-questions__input"
            @country-changed="Test"
          ></vue-tel-input>
          <label for="tel" class="form-questions__label">{{ t("tel") }}</label>
        </fieldset>
        <fieldset
          class="form-questions__item textarea"
          v-bind:class="{ focus: valueTextarea }"
        >
          <label for="textarea" class="form-questions__label">{{
            t("comment")
          }}</label>
          <textarea
            class="form-questions__textarea"
            name="textarea"
            id="textarea"
            v-model="textarea"
          ></textarea>
        </fieldset>

        <vue-recaptcha
          style="margin-top: 10px; text-align: -webkit-center"
          v-if="showRecaptcha"
          siteKey="6LeGLsocAAAAAKonw1p8EnGzVRjXsefuUZOxS0zV"
          size="normal"
          theme="light"
          :tabindex="0"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          @fail="recaptchaFailed"
          ref="vueRecaptcha"
        >
        </vue-recaptcha>

        <button
          type="submit"
          class="form-questions__btn"
          @click="submitForm"
          v-if="alertStatus === false"
        >
          {{ t("push") }}
        </button>

        <div class="form-questions__alert" v-if="alertStatus === true">
          <p class="alert-style">
            {{ t("Дякуємо за звернення, чекайте дзвінок найближчим часом") }}
          </p>
        </div>
      </form>
    </transition>
  </section>
</template>

<script>
import { ref } from "vue";
import phoneCodes from "@/assets/phone-codes.json";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import useValidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { reactive, computed } from "vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import vueRecaptcha from "vue3-recaptcha2";
import axios from "axios";

export default defineComponent({
  name: "form-common",

  data() {
    return {
      codes: phoneCodes,
      mask: "+ ## (###) ###-##-##",
      pref: null,
      token: null,
      showRecaptcha: true,
      alertStatus: false,
      valueName: false,
      valueEmail: false,
      valueTel: false,
      valueTextarea: false,
      "state.name": this.value,
      "state.email": this.value,
      "state.tel": this.value,
      textarea: this.value,
      show: false,
    };
  },

  components: {
    VueTelInput,
    vueRecaptcha,
  },

  setup() {
    const phone = ref(null);
    const { t } = useI18n();
    const state = reactive({
      name: "",
      email: "",
      tel: "",
      textarea: "",
    });

    const rules = computed(() => {
      return {
        name: { required },
        email: { required, email },
        tel: { required },
      };
    });

    const v$ = useValidate(rules, state);

    return {
      phone,
      t,
      state,
      v$,
    };
  },

  methods: {
    Test(ev) {
      let codes = [];
      codes = this.codes.filter((element) => {
        if (element.cc === ev.iso2) {
          return element;
        }
      });
      this.mask = codes[0].mask;
      this.pref = ev.dialCode;
    },

    recaptchaVerified(response) {
      this.token = response;
    },

    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },

    recaptchaFailed() {},

    submitForm(e) {
      e.preventDefault();
      let self = this;
      //Если все поля верны отправляем запрос
      this.v$.$validate().then(function (promiseResult) {
        if (promiseResult) {
          if (self.token.length > 0) {
            self.postForm(
              self.state.name,
              self.state.email,
              self.state.tel,
              self.textarea
            );
          }
        }
      });
    },

    async postForm(name, email, tel, description) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        await axios.post(
          process.env.VUE_APP_API + "/api/forms",
          {
            name: name,
            email: email,
            tel: tel,
            description: description,
          },
          config
        );
      } catch (e) {
        alert("Ошибка запроса");
      } finally {
        //Вызов аллерта
        this.alertStatus = true;

        //Очистка инпутов + убираем красную подсветку
        this.state.name = "";
        this.state.email = "";
        this.state.tel = "";
        this.textarea = "";

        this.v$.name = true;
        this.v$.email = true;
        this.v$.tel = true;
      }
    },

    toggle: function () {
      this.show = !this.show;
    },

    beforeEnter: function (el) {
      el.style.height = "0";
    },

    enter: function (el) {
      el.style.height = el.scrollHeight + "px";

      setTimeout(() => {
        el.style.height = "auto";
      }, 500);
    },

    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + "px";
    },

    leave: function (el) {
      el.style.height = "0";
    },
  },

  watch: {
    "state.name"(currentValue) {
      this.$emit("input", currentValue);
      if (currentValue.length > 0) {
        this.valueName = true;
      } else {
        this.valueName = false;
      }
    },
    "state.email"(currentValue) {
      this.$emit("input", currentValue);
      if (currentValue.length > 0) {
        this.valueEmail = true;
      } else {
        this.valueEmail = false;
      }
    },
    "state.tel"(currentValue) {
      this.$emit("input", currentValue);
      if (currentValue.length > 0) {
        this.valueTel = true;
      } else {
        this.valueTel = false;
      }
    },
    textarea(currentValue) {
      this.$emit("textarea", currentValue);
      if (currentValue.length > 0) {
        this.valueTextarea = true;
      } else {
        this.valueTextarea = false;
      }
    },
  },

  mounted() {
    const vueTelInputCommon = document.querySelectorAll(
      ".form-common .vue-tel-input"
    );

    for (let index = 0; index < vueTelInputCommon.length; index++) {
      const element = vueTelInputCommon[index];
      const label = element.nextElementSibling;

      element.append(label);
    }
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.alert-style {
  color: green;
  width: 300px;
  margin: 10px;
  border: 1px solid green;
  padding: 10px;
  text-align: center;
}

.form-common {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;

  @include property("padding-top", 70, 40, true, 1024, 320);
  @include property("padding-bottom", 60, 30, true, 1024, 320);

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #1c1c1c;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0.25;
    background: url("~@/assets/images/form-bg.webp") center center no-repeat;
    background-size: cover;
  }

  &__title {
    position: relative;
    z-index: 1;
    text-align: center;
    @include property("font-size", 32, 24);
    line-height: calc(42 / 32);
    letter-spacing: 1.6px;
    color: #f9b80e;
    text-transform: uppercase;
    margin-bottom: 11px;
  }

  &__subTitle {
    position: relative;
    z-index: 1;
    text-align: center;
    @include property("font-size", 32, 24);
    line-height: calc(42 / 32);
    letter-spacing: 1.6px;
    color: #e1e1e1;

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.form-questions {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  max-width: 812px;
  width: 100%;

  height: 0;
  overflow: hidden;
  transition: 0.5s;

  &__item {
    position: relative;
    margin: 0 auto;

    @include property("margin-top", 45, 30, true, 1024, 320);

    &:not(.textarea) {
      max-width: 536px;
      width: 100%;

      label {
        width: 100%;
        text-align: center;
      }
    }

    &.textarea {
      width: 100%;
      label {
        position: absolute;
        top: 30px;
        left: 20px;
        transform: translate(0, -50%);
        color: #000;
      }

      &.focus {
        label {
          top: 10px !important;
        }
      }
    }
  }

  &__input {
    padding: 15px 20px;
    border-bottom: 1px solid #e1e1e1;
    width: 100%;
    font-size: 18px;
    font-style: italic;
    line-height: calc(30 / 18);
    letter-spacing: 0.9px;
    color: #e1e1e1;
    transition: all 0.3s linear;

    .error & {
      color: red;
      border-color: red;
    }
  }

  &__label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-style: italic;
    line-height: calc(30 / 18);
    letter-spacing: 0.9px;
    color: #e1e1e1;
    cursor: text;
    transition: all 0.3s linear;

    .error & {
      color: red;
      top: 0;
    }

    .focus & {
      top: 0 !important;
    }
  }

  &__alert {
    margin-left: auto;
    margin-right: auto;
  }

  &__textarea {
    padding: 20px;
    background: #fff;
    width: 100%;
    min-height: 247px;
  }

  &__btn {
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    padding: 9px 32px;
    text-align: center;
    letter-spacing: 0.8px;
    color: #e1e1e1;
    align-self: center;
    margin-top: 40px;
    transition: all 0.3s linear;

    @media (hover: hover) {
      &:hover {
        color: #f9b80e;
        border-color: #f9b80e;
      }
    }
  }
}
</style>
